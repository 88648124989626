import { createId } from '@paralleldrive/cuid2';
import toast from 'react-hot-toast';

import { getClient } from '../../../contexts/websocket-context';
import { Emitter } from '../../../utils/emitter';
import { DisposableStore } from '../../../utils/disposable';
import { captureException } from '@sentry/react';
import type {
  ResponseType as UploadRequestResponseType,
  BodyType as UploadRequestPayload,
} from '../../workspaceDocument/endpoints/WorkspaceDocumentUploadRequestEndpoint';
import type {
  ResponseType as NotifyUploadCompletedResponseType,
  BodyType as NotifyUploadCompletedPayload,
} from '../../workspaceDocument/endpoints/WorkspaceDocumentUploadCompletedNotifyEndpoint';
import { fetchEndpointData } from '@/utils/fetch.client';

export class WorkspaceState {
  private websocket = getClient();
  private companiesSubscribeDisposable = new DisposableStore();

  private reconnectHash = Date.now().toString(16);
  private reconnectEmitter = new Emitter<string>();
  public onReconnect = this.reconnectEmitter.event;

  public companiesVersionHash = Date.now().toString(16);
  private companiesUpdateEmitter = new Emitter<string>();
  public onCompaniesUpdate = this.companiesUpdateEmitter.event;

  private documentsUpdateEmitter = new Emitter<string>();
  public onDocumentsUpdate = this.documentsUpdateEmitter.event;

  private foldersUpdateEmitter = new Emitter<string>();
  public onFoldersUpdate = this.foldersUpdateEmitter.event;

  private categoriesUpdateEmitter = new Emitter<string>();
  public onCategoriesUpdate = this.categoriesUpdateEmitter.event;

  public pendingUploads = new Map<
    string,
    {
      progress: number;
      error: string | null;
      folderId: string | null;
      file: File;
      fileType: string;
    }
  >();
  private uploadChangeEmitter = new Emitter<string>();
  public onUploadChange = this.uploadChangeEmitter.event;

  constructor(
    public workspaceId: string,
    public teamId: string,
  ) {
    this.websocket.onConnect(() => {
      this.subscribe().catch((err) => {
        captureException(err);
        toast.error(err.message);
      });
    });

    this.subscribe().catch((err) => {
      captureException(err);
      toast.error(err.message);
    });
  }

  private updateCompaniesVersionHash() {
    this.companiesVersionHash = Date.now().toString(16);
    this.companiesUpdateEmitter.fire(this.companiesVersionHash);
  }

  private updateReconnectHash(): void {
    this.reconnectHash = Date.now().toString(16);
    this.reconnectEmitter.fire(this.reconnectHash);

    // Update everything...
    this.updateCompaniesVersionHash();
    this.foldersUpdateEmitter.fire(this.reconnectHash);
    this.documentsUpdateEmitter.fire(this.reconnectHash);
    this.categoriesUpdateEmitter.fire(this.reconnectHash);
  }

  private cleanupCompaniesSubscription() {
    this.companiesSubscribeDisposable.dispose();
    this.companiesSubscribeDisposable = new DisposableStore();
  }

  private cleanupSubscription() {
    this.cleanupCompaniesSubscription();
  }

  private subscribeCompanies(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msgRef = createId();
      let isResolved = false;

      this.companiesSubscribeDisposable.add(
        this.websocket.onMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved && message.method === 'workspace/subscribe-ack') {
              resolve();
              this.updateReconnectHash();
              isResolved = true;
            }

            switch (message.method) {
              case 'workspace/companies-analysed': {
                this.updateCompaniesVersionHash();
                break;
              }
              case 'workspace/folder-update': {
                this.foldersUpdateEmitter.fire(message.data.timestamp.toString(16));
                break;
              }
              case 'workspace/document-update': {
                this.documentsUpdateEmitter.fire(message.data.timestamp.toString(16));
                break;
              }
              case 'workspace/category-update': {
                this.categoriesUpdateEmitter.fire(message.data.timestamp.toString(16));
                break;
              }
            }
          }
        }),
      );
      this.companiesSubscribeDisposable.add(
        this.websocket.onErrorMessage((message) => {
          if (message.ref === msgRef) {
            if (!isResolved) {
              reject(new Error(message.error.message));
              isResolved = true;
            }
            this.cleanupCompaniesSubscription();
          }
        }),
      );
      this.websocket.send({
        ref: msgRef,
        method: 'workspace/subscribe',
        data: {
          workspaceId: this.workspaceId,
        },
      });
    });
  }

  private subscribe(): Promise<void> {
    this.cleanupSubscription();

    return this.subscribeCompanies();
  }

  private emitUploadChanges() {
    this.uploadChangeEmitter.fire(Date.now().toString(16));
  }

  public startUpload(opts: { folderId: string | null; file: File; fileType: string }) {
    const { folderId, file, fileType } = opts;
    const uploadId = createId();
    this.pendingUploads.set(uploadId, {
      progress: 0,
      error: null,
      folderId,
      file,
      fileType,
    });
    const uploadFile = async () => {
      try {
        const payload: UploadRequestPayload = {
          filename: file.name,
          mimetype: fileType,
          folderId: folderId,
          workspaceId: this.workspaceId,
          size: file.size,
        };
        const uploadRequestResult = await fetchEndpointData<UploadRequestResponseType>(
          '/api/v1/workspace/document/upload-request',
          {
            method: 'POST',
            body: payload,
          },
        );

        const _upload = () => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.set('my-file', file);

            const request = new XMLHttpRequest();

            // Handle successful completion
            request.onload = () => {
              if (request.status >= 200 && request.status < 300) {
                resolve(request.response);
              } else {
                reject(new Error(`${request.status}: ${request.statusText}`));
              }
            };

            // Handle network errors
            request.onerror = () => {
              reject(new Error('Network error'));
            };

            // Progress event
            request.upload.addEventListener('progress', (evt) => {
              const pending = this.pendingUploads.get(uploadId);
              if (pending) {
                pending.progress = Math.round((evt.loaded / file.size) * 100);
                this.emitUploadChanges();
              }
            });

            // Send request
            request.open('PUT', uploadRequestResult.signedUrl);

            // Set headers
            for (const [key, value] of Object.entries(uploadRequestResult.headers)) {
              request.setRequestHeader(key, value);
            }

            request.send(file);
          });
        };

        await _upload();

        const notifyCompletedUploadPayload: NotifyUploadCompletedPayload = {
          filename: uploadRequestResult.filename,
        };
        await fetchEndpointData<NotifyUploadCompletedResponseType>(
          '/api/v1/workspace/document/upload-completed-notify',
          {
            method: 'POST',
            body: notifyCompletedUploadPayload,
          },
        );
      } catch (err) {
        captureException(err);
        throw err;
      }
    };
    uploadFile()
      .then(() => {
        this.pendingUploads.delete(uploadId);
        this.emitUploadChanges();
      })
      .catch(() => {
        const pending = this.pendingUploads.get(uploadId);
        if (pending) {
          pending.error = 'Failed to upload file';
          this.emitUploadChanges();
        }
      });
    this.emitUploadChanges();
  }

  retryUpload(uploadId: string) {
    const pending = this.pendingUploads.get(uploadId);
    if (pending) {
      this.pendingUploads.delete(uploadId);

      this.startUpload({
        folderId: pending.folderId,
        file: pending.file,
        fileType: pending.fileType,
      });
    }
  }
}

export class WorkspaceStatesStore {
  workspaces = new Map<string, WorkspaceState>();

  getState(workspaceId: string, teamId: string): WorkspaceState {
    let workspace = this.workspaces.get(workspaceId);
    if (!workspace) {
      workspace = new WorkspaceState(workspaceId, teamId);
      this.workspaces.set(workspaceId, workspace);
    }
    return workspace;
  }
}

let _workspaceStates: WorkspaceStatesStore | null = null;
export function getWorkspaceStates(): WorkspaceStatesStore {
  if (!_workspaceStates) {
    _workspaceStates = new WorkspaceStatesStore();
  }
  return _workspaceStates;
}

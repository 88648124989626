import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { FilePlus as FilePlusIcon } from '@phosphor-icons/react';
import mime from 'mime';

import { useTeam } from '@/app/team/context/TeamContext';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';

export interface IFullPageDropUploadProps {
  folderId: string | null;
  allowedTypes: string[];
}

export const FullPageDropUpload: React.FC<IFullPageDropUploadProps> = (props) => {
  const { folderId, allowedTypes } = props;
  const { team } = useTeam();
  const { syncState } = useWorkspace();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDroppingFiles, setIsDroppingFiles] = useState<number>(0);
  const dragCountRef = useRef(0);

  const lastUpdatedRef = useRef(Date.now());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const allowedTypesSet = new Set(allowedTypes);
    const updateDragCounter = (diff: number) => {
      dragCountRef.current = dragCountRef.current + diff;
    };

    const handleWindowDragEnter = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      updateDragCounter(1);

      if (evt.dataTransfer && evt.dataTransfer.items && evt.dataTransfer.items.length > 0) {
        setIsDroppingFiles(evt.dataTransfer.items.length);
      }
    };

    const handleWindowDragLeave = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      updateDragCounter(-1);

      if (dragCountRef.current === 0) {
        setIsDroppingFiles(0);
      }
    };

    const handleWindowDragOver = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      const diff = Date.now() - lastUpdatedRef.current;
      if (diff < 10) {
        timeoutRef.current = setTimeout(() => {
          setPosition({
            x: evt.clientX,
            y: evt.clientY,
          });
        }, diff);
      } else {
        lastUpdatedRef.current = Date.now();
        setPosition({
          x: evt.clientX,
          y: evt.clientY,
        });
      }
    };

    const handleWindowDrop = (evt: DragEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      setIsDroppingFiles(0);

      if (evt.dataTransfer) {
        const droppedFiles = [...evt.dataTransfer.files];
        if (droppedFiles?.length > 0) {
          for (const file of droppedFiles) {
            const fileType = file.type || mime.getType(file.name) || 'application/octet-stream';
            if (!allowedTypesSet.has(fileType)) {
              toast.error(`File type ${fileType} is not allowed`);
              continue;
            }

            const mbSize = file.size / 1024 / 1024;
            if (mbSize > team.uploadLimit) {
              toast.error(`File size ${mbSize}MB exceeds the upload limit ${team.uploadLimit}MB`);
              continue;
            }

            syncState.startUpload({
              folderId,
              file,
              fileType,
            });
          }
        }
      }
    };

    // Add window event listeners
    window.addEventListener('dragenter', handleWindowDragEnter);
    window.addEventListener('dragleave', handleWindowDragLeave);
    window.addEventListener('dragover', handleWindowDragOver);
    window.addEventListener('drop', handleWindowDrop);

    // Cleanup
    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter);
      window.removeEventListener('dragleave', handleWindowDragLeave);
      window.removeEventListener('dragover', handleWindowDragOver);
      window.removeEventListener('drop', handleWindowDrop);
    };
  }, [folderId, allowedTypes]);

  if (isDroppingFiles < 1) {
    return null;
  }

  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 z-dialog p-2"
      style={{
        backgroundColor: 'rgba(100, 132, 234, 0.2)',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: position.y,
          left: position.x,
        }}
      >
        <div className="relative">
          {new Array(isDroppingFiles).fill(0).map((_, index) => {
            const angle = (index / isDroppingFiles) * 2 * Math.PI;
            const radius = 120;
            const x = isDroppingFiles === 1 ? 0 : Math.cos(angle) * radius;
            const y = isDroppingFiles === 1 ? 0 : Math.sin(angle) * radius;
            const rotation = Math.sin(Date.now() * 0.001 + index) * 45;

            return (
              <FilePlusIcon
                key={`file-icon-${index}`}
                className="absolute w-12 h-12 fill-primary-500 transition-all duration-200 ease-out"
                style={{
                  transform: `translate(${x - 16}px, ${y - 16}px) rotate(${rotation}deg)`,
                }}
              />
            );
          })}
        </div>
      </div>

      <div className="border-2 border-dashed border-blue-400 rounded-lg w-full h-full flex justify-center items-center">
        <div className="text-xl">Drop Files to upload</div>
      </div>
    </div>
  );
};
